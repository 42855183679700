import React from "react"

import { Link, graphql } from "gatsby"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Meta from "../components/meta"
import Layout from "../components/layouts/index_layout"
import { GatsbyImage } from "gatsby-plugin-image"

import Carousel from "react-bootstrap/Carousel"


const Index = (props) => (
  <Layout>
    <Meta
      title="threesixty Investments Ltd"
      description="threesixty Investments Ltd take a long term approach to creating value and growing talent within our investments." />
    <Container>
      <Carousel
        indicators={true}
        controls={true}
        slide={true}
        interval={null}
      >
        <Carousel.Item>
          <Row>
            <Col md={4} className="align-self-center m-1">
              <h3>threesixty</h3>
              <p className="text-start">
                We look to acquire, fund and grow businesses within the SME arena.
              </p>
              <p className="text-start">
                We take a long term approach to creating value and growing talent within our investments.
              </p>
            </Col>
            <Col>
              <GatsbyImage image={props.data.carouselMain.childImageSharp.gatsbyImageData} className="imageCard mt-3 mt-sm-0 index-img d-block w-100" alt="threesixty" />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col md={4} className="align-self-center m-1">
              <h3>threesixty investments</h3>
              <p className="text-start">
                threesixty Investments Ltd is an investment vehicle with an aim to acquire trading businesses,
                broadly with sales volumes within the range of £0.5m-£20m.
              </p>
              <Link
                to="investments"
                className="btn d-flex-inline text-investments-light btn-investments-light-accent"
                role="button"
              >
                Read More
              </Link>
            </Col>
            <Col className="d-flex flex-column">
              <GatsbyImage image={props.data.carouselOne.childImageSharp.gatsbyImageData} className="imageCard mt-3 mt-sm-0 index-img d-block w-100" alt="threesixty investments" />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item className="">
          <Row>
            <Col md={4} className="align-self-center m-1">
              <h3>threesixty commercial finance</h3>
              <p className="text-start">
                We have a breadth of knowledge and experience working for banks
                and funders.
              </p>
              <p className="text-start">
                Our vast knowledge of the lending market and the personnel within
                it means that we can pitch the deal to the right person – very
                often going to the top of an organisation for an answer. That way,
                we save a lot of time and trouble.
              </p>
              <Link
                to="commercial"
                className="btn d-flex-inline text-investments-light btn-investments-light-accent"
                role="button"
              >
                Read More
              </Link>
            </Col>
            <Col className="d-flex flex-column">
              <GatsbyImage image={props.data.carouselFour.childImageSharp.gatsbyImageData} className="imageCard mt-3 mt-sm-0 index-img d-block w-100" alt="threesixty commercial finance" />
            </Col>
          </Row>
        </Carousel.Item>
        {/* <Carousel.Item className="">
          <Row>
            <Col md={4} className="align-self-center m-1">
              <h3>threesixty collateral management</h3>
              <p className="text-start">
                threesixty Collateral Management can offer a more holistic approach to debt recovery and security management due to the in-depth knowledge that the Directors have of the Asset Based Lending and Restructuring markets.
              </p>
              <Link
                to="collateral"
                className="btn d-flex-inline text-investments-light btn-investments-light-accent"
                role="button"
              >
                Read More
              </Link>
            </Col>
            <Col className="d-flex flex-column">
              <GatsbyImage image={props.data.carouselThree.childImageSharp.gatsbyImageData} className="imageCard mt-3 mt-sm-0 index-img d-block w-100" alt="threesixty collateral management" />
            </Col>
          </Row>
        </Carousel.Item> */}
      </Carousel>
    </Container>
  </Layout>
)

export default Index;

export const carouselImage = graphql`
  fragment carouselImage on File {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
`

export const query = graphql`
  query {
    carouselMain: file(relativePath: {eq: "directors.jpg"}) {
      ...carouselImage
    }
    carouselOne: file(relativePath: {eq: "photo-1579621970588-a35d0e7ab9b6.jpg"}) {
      ...carouselImage
    }
    carouselTwo: file(relativePath: {eq: "photo-1517048676732-d65bc937f952.jpg"}) {
      ...carouselImage
    }
    carouselThree: file(relativePath: {eq: "purse-3548021_640.jpg"}) {
      ...carouselImage
    }
    carouselFour: file(relativePath: {eq: "photo-1459257831348-f0cdd359235f.jpg"}) {
      ...carouselImage
    }
  }
`
