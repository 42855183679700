import React from "react"

import Container from "react-bootstrap/Container"
import ThemeProvider from "react-bootstrap/ThemeProvider"

import Header from "../header"
import Footer from "../footer"

class Layout extends React.Component {
  render() {
    const { logo, children } = this.props;

    return (
      <ThemeProvider
        breakpoints={['lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      >
        <Container fluid="true" className="site px-0 main cover-container d-flex w-100 h-100 mx-auto flex-column">
          <Header
            borderColour="border-threesixty-orange"
            logo={logo}
            textColour="text-black"
          />
          <main className="site-content mt-5 mb-5">{children}</main>
          <Footer />
        </Container>
      </ThemeProvider>
    )
  }
}

Layout.defaultProps = {
  logo: "logo_svg_blank_orange.svg"
}

export default Layout